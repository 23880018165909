import { CollectibleHistoricalStats } from '../../../sci-ui-components/types/collectibleHistoricalStats';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiHistoricalStats } from '../types';
import fetchFromSciApi from '../fetchFromSciApi';
import collectibleHistoricalStatsFromApi from './transformers/collectibleHistoricalStatsFromApi';
import getStatsCollectibleTypeParam from './utils/getStatsCollectibleTypeParam';

export interface GetCollectibleStatsParams {
  collectibleType: CollectibleType;
  collectibleId: number;
  isPublic?: boolean | undefined;
}

export async function getCollectibleStats(
  params: GetCollectibleStatsParams,
  signal: AbortSignal | undefined
): Promise<CollectibleHistoricalStats> {
  const { collectibleType, collectibleId, isPublic } = params;
  const apiResponse = isPublic
    ? await fetchFromSciApi<ApiHistoricalStats>(
        `/${getStatsCollectibleTypeParam(collectibleType)}/stats/${collectibleId}/public`,
        {
          method: 'GET',
          signal,
        }
      )
    : await authenticatedFetchFromSciApi<ApiHistoricalStats>(
        `/${getStatsCollectibleTypeParam(collectibleType)}/stats/${collectibleId}`,
        {
          method: 'GET',
          signal,
        }
      );
  return collectibleHistoricalStatsFromApi(apiResponse, collectibleType);
}
