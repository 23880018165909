import { SortDirection } from '../../../sci-ui-components/types/sort';
import {
  StatsMovementsFilters,
  StatsPeriod,
  StatsMovements,
  StatsMovementsGroupByValue,
  GetStatsMovementsSortByValue,
} from '../../../sci-ui-components/types/collectibleStats';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiItemStatsMovements } from './types';
import statsMovementFromApi from './transformers/statsMovementFromApi';
import getStatsCollectibleTypeParam from './utils/getStatsCollectibleTypeParam';

interface GetStatsMovementsApiPayload {
  gradedCardsOnly: boolean;
  grades: string[]; // names =(
  group: string | boolean; // group by player or return individual collectibles -- API expects string if true
  limit: number;
  offset: number;
  orderBy: SortDirection;
  period: StatsPeriod;
  players: number[]; // ids
  rcOnly: boolean;
  set_variations?: number[]; // ids
  sets: string[]; // names =(
  sortBy: GetStatsMovementsSortByValue;
  sports: number[]; // ids
  years: number[];
  maxAvgSale?: number;
  maxSaleCount?: number;
  minAvgSale?: number;
  minSaleCount?: number;
  boxTypes?: number[]; // ids
  personalCollection?: boolean;
  collectionCategory?: number | null;
}

interface GetStatsMovementsApiResponse {
  data: ApiItemStatsMovements[];
  totalCount: number;
  totalPages: number;
}

export type GetStatsMovementsFilters = Omit<StatsMovementsFilters, 'cardSetVariationNames'> & {
  cardSetVariationIds?: number[] | null;
};

export interface GetStatsMovementsParams {
  collectibleType: CollectibleType;
  offset: number;
  limit: number;
  filters: GetStatsMovementsFilters;
  groupBy?: StatsMovementsGroupByValue | null;
  sortDirection: SortDirection | null;
  sortBy: GetStatsMovementsSortByValue | null;
  period: StatsPeriod;
  isForMyCollectionOnly?: boolean;
  collectionCategoryId?: number | null;
}

export interface GetStatsMovementsResponse {
  totalCount: number;
  items: StatsMovements[];
}

export async function getStatsMovements(
  params: GetStatsMovementsParams,
  signal: AbortSignal | undefined
): Promise<GetStatsMovementsResponse> {
  const { collectibleType, groupBy, limit, offset } = params;
  const isGrouped = !!groupBy;
  const payload: GetStatsMovementsApiPayload = {
    ...getStatsCommonApiParams(params),
    limit,
    // NOTE: api has a bug where it treats 'offset' param as 'page', where offset = page * limit
    offset: limit ? Math.floor(offset / limit) : 0,
  };
  const apiResponse = await authenticatedFetchFromSciApi<GetStatsMovementsApiResponse>(
    `/${getStatsCollectibleTypeParam(collectibleType)}/stats/all`,
    {
      method: 'POST',
      signal,
      body: JSON.stringify(payload),
    }
  );
  return {
    totalCount: apiResponse.totalCount,
    items: apiResponse.data?.map((apiItem) => statsMovementFromApi(apiItem, collectibleType, isGrouped)) ?? [],
  };
}

export async function getStatsMovementsCsvFile(
  params: Omit<GetStatsMovementsParams, 'limit' | 'offset'>,
  signal: AbortSignal | undefined
) {
  const { collectibleType } = params;
  const payload = getStatsCommonApiParams(params);
  const url = `/${getStatsCollectibleTypeParam(collectibleType)}/stats/csv`;
  const apiResponse = await authenticatedFetchFromSciApi<{ csv: Blob }>(url, {
    method: 'POST',
    signal,
    headers: {
      Accept: 'text/csv',
    },
    body: JSON.stringify(payload),
  });
  return apiResponse;
}

function getStatsCommonApiParams({
  filters,
  groupBy,
  period,
  sortBy,
  sortDirection,
  isForMyCollectionOnly,
  collectionCategoryId,
}: Omit<GetStatsMovementsParams, 'limit' | 'offset'>): Omit<GetStatsMovementsApiPayload, 'limit' | 'offset'> {
  const isGrouped = !!groupBy;
  const commonParams = {
    sortBy: sortBy ?? 'percent_change',
    orderBy: sortDirection ?? 'desc',
    period,
    gradedCardsOnly: filters.gradedCardsOnly ?? false,
    grades: filters.cardGradeNames ?? [],
    players: filters.playerIds ?? [],
    rcOnly: filters.rookieOnly ?? false,
    set_variations: filters.cardSetVariationIds ?? [],
    sets: filters.cardSetNames ?? [],
    sports: filters.sportIds ?? [],
    years: filters.cardSetYears ?? [],
    boxTypes: filters.sealedWaxBoxTypeIds,
    maxSaleCount: filters.maxSaleCount,
    minSaleCount: filters.minSaleCount,
    maxAvgSale: filters.maxAvgSale,
    minAvgSale: filters.minAvgSale,
    personalCollection: isForMyCollectionOnly ?? false,
    collectionCategory: isForMyCollectionOnly ? collectionCategoryId ?? null : null,
    group: isGrouped ? 'true' : false,
  };

  return commonParams;
}
