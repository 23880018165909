import { useQuery, UseQueryOptions } from 'react-query';
import {
  getStatsMovements,
  GetStatsMovementsParams,
  GetStatsMovementsResponse,
} from '../../services/sciApi/stats/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import useFilteredData from '../../hooks/useFilteredData';
import { StatsMovementsFilters } from '../../sci-ui-components/types/collectibleStats';

export interface ClientSideFilterParams {
  searchText?: string | null;
  filters: StatsMovementsFilters;
}

export type {
  StatsMovementsGroupByValue,
  GetStatsMovementsSortByValue,
} from '../../sci-ui-components/types/collectibleStats';

export const keyPrefix = 'period-stats';
export const getQueryKey = (
  { isForMyCollectionOnly = false, ...rest }: Partial<GetStatsMovementsParams> = {
    isForMyCollectionOnly: false,
  }
): PaginatedKey => [keyPrefix, isForMyCollectionOnly, rest as GetStatsMovementsParams];

export type PaginatedQueryParams = GetStatsMovementsParams & ClientSideFilterParams;
type PaginatedKey = [typeof keyPrefix, boolean, Omit<GetStatsMovementsParams, 'isForMyCollectionOnly'>];

export function useStatsMovementsPaginated(
  { searchText, ...params }: PaginatedQueryParams,
  options: UseQueryOptions<GetStatsMovementsResponse, ApiError, GetStatsMovementsResponse, PaginatedKey> = {}
) {
  const { isLoggedIn } = useAuth();
  const { data, ...otherQueryResults } = useQuery(
    getQueryKey(params),
    async ({ signal }) => getStatsMovements(params, signal),
    {
      staleTime: 1000 * 60 * 60 * 6, // 6 hours
      ...options,
      enabled: !!isLoggedIn && (options?.enabled ?? true),
    }
  );

  const filteredItems = useFilteredData(data?.items ?? [], 'itemTextDescription', searchText);

  return {
    ...otherQueryResults,
    data: data
      ? {
          ...data,
          items: filteredItems,
        }
      : null,
  };
}
