import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';

export default function getStatsCollectibleTypeParam(collectibleType: CollectibleType) {
  switch (collectibleType) {
    case 'sports-card':
      return 'card';
    case 'sealed-wax-card':
      return 'sealed-waxes';
    default:
      return 'card';
  }
}
