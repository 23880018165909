import {
  StatsMovements,
  StatsMovementsCollectible,
  StatsMovementsGroup,
} from '../../../../sci-ui-components/types/collectibleStats';
import { CollectibleType, isSportsCardCollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import {
  ApiItemStatsMovements,
  ApiItemStatsMovementsSealedWax,
  ApiItemStatsMovementsSportsCardByCard,
  ApiItemStatsMovementsSportsCardByPlayer,
} from '../types';

export default function periodStatFromApi(
  apiItem: ApiItemStatsMovements,
  collectibleType: CollectibleType,
  isGrouped: boolean
): StatsMovements {
  if (!isSportsCardCollectibleType(collectibleType)) {
    const waxApiItem = apiItem as ApiItemStatsMovementsSealedWax;
    const result: StatsMovementsCollectible = {
      isGrouped: false,
      itemKey: String(waxApiItem.key),
      lastSale: String(waxApiItem.last_sale),
      avgSalePrice: Number(waxApiItem.avg),
      endAvgSalePrice: Number(waxApiItem.end_avg),
      maxSalePrice: Number(waxApiItem.max),
      minSalePrice: Number(waxApiItem.min),
      numberOfSales: Number(waxApiItem.count),
      priceChangeAmount: Number(waxApiItem.change),
      priceChangePercentage: Number(waxApiItem.percent_change),
      startAvgSalePrice: Number(waxApiItem.start_avg),
      totalSalesAmount: Number(waxApiItem.total),
      volumeChangePercentage: Number(waxApiItem.volume_change_percentage),
      collectibleId: waxApiItem.id,
      itemTextDescription: waxApiItem.title,
      imageUrl: waxApiItem.image_url,
    };
    return result;
  }
  if (isGrouped) {
    const playerApiItem = apiItem as ApiItemStatsMovementsSportsCardByPlayer;
    const result: StatsMovementsGroup = {
      isGrouped: true,
      itemKey: String(playerApiItem.key),
      itemTextDescription: playerApiItem.player,
      lastSale: String(playerApiItem.last_sale),
      avgSalePrice: Number(playerApiItem.avg),
      maxSalePrice: Number(playerApiItem.max),
      minSalePrice: Number(playerApiItem.min),
      numberOfSales: Number(playerApiItem.count),
      priceChangePercentage: Number(playerApiItem.percent_change),
      totalSalesAmount: Number(playerApiItem.total),
      volumeChangePercentage: Number(playerApiItem.volume_change_percentage),
      groupId: playerApiItem.id,
      groupDescription: playerApiItem.player,
      numberOfCollectibles: Number(playerApiItem.card),
      numberOfCollectiblesWithSales: Number(playerApiItem.count_cards_with_sales),
    };
    return result;
  }
  const cardApiItem = apiItem as ApiItemStatsMovementsSportsCardByCard;
  const result: StatsMovementsCollectible = {
    isGrouped: false,
    itemKey: String(cardApiItem.key),
    itemTextDescription: cardApiItem.card,
    avgSalePrice: Number(cardApiItem.avg),
    endAvgSalePrice: Number(cardApiItem.end_avg),
    lastSale: String(cardApiItem.last_sale),
    maxSalePrice: Number(cardApiItem.max),
    minSalePrice: Number(cardApiItem.min),
    numberOfSales: Number(cardApiItem.count),
    priceChangeAmount: Number(cardApiItem.change),
    priceChangePercentage: Number(cardApiItem.percent_change),
    startAvgSalePrice: Number(cardApiItem.start_avg),
    totalSalesAmount: Number(cardApiItem.total),
    volumeChangePercentage: Number(cardApiItem.volume_change_percentage),
    collectibleId: cardApiItem.id,
    imageUrl: cardApiItem.image_url,
  };
  return result;
}
